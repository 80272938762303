import { setSentrySessionUser } from '../../../helpers/tools/sentry';
import { verifyPinApiCall } from '../../entities/Authentication/AuthenticationService';
import { DashboardSetting, EditDashboardFormData } from '../../entities/DashboardSetting/DashboardSetting';
import { transformEditDashboardFormDataToDashboardSettingIds } from '../../entities/DashboardSetting/DashboardSettingTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult';
import { AuthenticatedUser, UserProfile } from '../../entities/User/User';
import { getUserProfileV3ApiCall, patchUserDashboardSettingsCall } from '../../entities/User/UserService';
import { TypedDispatch } from '../store';
import { setIsLoading } from '../user/userReducer';
import {
    setAuthenticatedUser,
    setError,
    setIsEditUserSuccessful,
    setIsUpdating,
    setIsUserIdle,
} from './authenticatedUserReducer';

export const patchAuthenticatedUserDashboardSettings = (
    formData: EditDashboardFormData,
    dashboardSettings: DashboardSetting[],
    user: AuthenticatedUser,
) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsUpdating(true));
    dispatch(setError(''));

    const dashboardSettingsIds = transformEditDashboardFormDataToDashboardSettingIds(formData, dashboardSettings);

    try {
        const response = await patchUserDashboardSettingsCall(dashboardSettingsIds, user.id);

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        dispatch(setIsEditUserSuccessful(true));
        dispatch(setAuthenticatedUser({
            ...user,
            dashboardSettings: response.data,
        }));
    } catch (error) {
        console.error('[patchAuthenticatedUserDashboardSettings]', error);
    } finally {
        dispatch(setIsUpdating(false));
    }
};

export const getProfileOfCurrentUser = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getUserProfileV3ApiCall();

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));

            return;
        }

        const user: UserProfile = {
            ...response.data,
            departments: response.data.departments.filter(department => !department.deletedAt),
        };

        setSentrySessionUser(response.data);
        dispatch(setAuthenticatedUser(user));
    } catch (error) {
        console.error('[getProfileOfCurrentUser]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const verifyPin = (employeeNumber: number, pin: string) => async (dispatch: TypedDispatch): Promise<boolean> => {
    dispatch(setIsLoading(true));

    try {
        const response = await verifyPinApiCall(employeeNumber, pin);

        if (!isFetchResultSuccessful(response)) {
            return false;
        }

        dispatch(setIsUserIdle(false));

        return true;
    } catch (error) {
        console.error('[verifyPin]', error);
    } finally {
        dispatch(setIsLoading(false));
    }

    return false;
};
