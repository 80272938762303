import { call, put, takeLatest } from 'redux-saga/effects';

import { setIsUserIdle } from '../../@paco/redux/authenticatedUser/authenticatedUserReducer';
import {
    authenticateUserRequest,
    patchUserPassword,
    postForgotPassword,
} from '../../helpers/api/authenticationApi';
import {
    APP_SAGA_INIT,
    APP_SAGA_LOGOUT,
    AUTH_AUTHENTICATE_FAILURE,
    AUTH_AUTHENTICATE_REQUEST,
    AUTH_AUTHENTICATE_SUCCESS,
    AUTH_FORGOT_PASSWORD_FAILURE,
    AUTH_FORGOT_PASSWORD_REQUEST,
    AUTH_FORGOT_PASSWORD_SUCCESS,
    AUTH_SAGA_AUTHENTICATE_USER,
    AUTH_SAGA_FORGOT_PASSWORD,
    AUTH_SAGA_SET_API_TOKEN_AND_GET_USER,
    AUTH_SAGA_SUBMIT_PASSWORD,
    AUTH_SAGA_UPDATE_API_TOKEN,
    AUTH_SAGA_UPDATE_REFRESH_TOKEN,
    AUTH_SET_API_TOKEN,
    AUTH_SET_API_TOKEN_AND_GET_USER_FAILURE,
    AUTH_SET_API_TOKEN_AND_GET_USER_REQUEST,
    AUTH_SET_REFRESH_TOKEN,
    AUTH_SUBMIT_PASSWORD_FAILURE,
    AUTH_SUBMIT_PASSWORD_REQUEST,
    AUTH_SUBMIT_PASSWORD_SUCCESS,
    AUTH_UNSET_TOKENS,
    CURRENT_USER_SAGA_GET_USER,
} from '../actionTypes';

function* authenticateUser(action) {
    yield put({ type: AUTH_AUTHENTICATE_REQUEST });

    try {
        const response = yield call(() => authenticateUserRequest(action.credentials));
        yield put(
            {
                type: AUTH_AUTHENTICATE_SUCCESS,
                apiToken: response.data.token,
                refreshToken: response.data.refresh_token,
            },
        );
        yield put({ type: APP_SAGA_INIT });
        yield put(setIsUserIdle(false));
    } catch (error) {
        yield put({ type: AUTH_AUTHENTICATE_FAILURE });
    }
}

function* setApiToken(action) {
    yield put({ type: AUTH_SET_API_TOKEN, apiToken: action.apiToken });
}

function* setRefreshToken(action) {
    yield put({ type: AUTH_SET_REFRESH_TOKEN, refreshToken: action.refreshToken });
}

function* unsetTokens() {
    yield put({ type: AUTH_UNSET_TOKENS });
}

function* setApiTokenAndGetUser({ params }) {
    yield put({ type: AUTH_SET_API_TOKEN_AND_GET_USER_REQUEST });

    try {
        yield put(
            {
                type: AUTH_AUTHENTICATE_SUCCESS,
                apiToken: params.api_token,
                refreshToken: params.refresh_token,
            },
        );
        yield put({ type: CURRENT_USER_SAGA_GET_USER });
    } catch (error) {
        yield put({ type: AUTH_SET_API_TOKEN_AND_GET_USER_FAILURE });
    }
}

export function* forgotPassword({ email, userId }) {
    yield put({ type: AUTH_FORGOT_PASSWORD_REQUEST });
    try {
        yield call(() => postForgotPassword(email, userId));
        yield put({ type: AUTH_FORGOT_PASSWORD_SUCCESS });
    } catch (error) {
        yield put({ type: AUTH_FORGOT_PASSWORD_FAILURE });
    }
}

function* submitPassword({ userId, password, pincode }) {
    yield put({ type: AUTH_SUBMIT_PASSWORD_REQUEST });
    try {
        yield call(() => patchUserPassword(userId, password, pincode));
        yield put({ type: AUTH_SUBMIT_PASSWORD_SUCCESS });
        yield put({ type: AUTH_UNSET_TOKENS });
    } catch (error) {
        yield put({ type: AUTH_SUBMIT_PASSWORD_FAILURE });
    }
}

export default function* authenticationWatcher() {
    yield takeLatest(AUTH_SAGA_AUTHENTICATE_USER, authenticateUser);
    yield takeLatest(AUTH_SAGA_UPDATE_API_TOKEN, setApiToken);
    yield takeLatest(AUTH_SAGA_UPDATE_REFRESH_TOKEN, setRefreshToken);
    yield takeLatest(APP_SAGA_LOGOUT, unsetTokens);
    yield takeLatest(AUTH_SAGA_SUBMIT_PASSWORD, submitPassword);
    yield takeLatest(AUTH_SAGA_FORGOT_PASSWORD, forgotPassword);
    yield takeLatest(AUTH_SAGA_SET_API_TOKEN_AND_GET_USER, setApiTokenAndGetUser);
}
