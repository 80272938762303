import { areIntervalsOverlapping } from 'date-fns';

import { ShiftViewModel, UserViewModel } from '../../../models';
import {
    doesShiftPlanningHaveAbsence,
    doesShiftPlanningHaveOpenLeaveOfAbsence,
} from '../ShiftPlanning/ShiftPlanningHelpers';
import { ShiftsCalendarAbsence } from '../ShiftsCalendarAbsence/ShiftsCalendarAbsence';
import { ShiftsCalendarLeaveOfAbsence } from '../ShiftsCalendarLeaveOfAbsence/ShiftsCalendarLeaveOfAbsence';
import { ShiftIndex } from './Shift';

export const doesShiftHaveAbsence = (shift: ShiftIndex, absences: ShiftsCalendarAbsence[]): boolean => shift.shiftPlannings
    .some(shiftPlanning => doesShiftPlanningHaveAbsence(shiftPlanning, absences));

export const doesShiftHaveOpenLeaveOfAbsence = (shift: ShiftIndex, leaveOfAbsences: ShiftsCalendarLeaveOfAbsence[]): boolean => shift.shiftPlannings
    .some(shiftPlanning => doesShiftPlanningHaveOpenLeaveOfAbsence(shiftPlanning, leaveOfAbsences));

export const getUsersWithOverlappingShifts = (shifts: ShiftViewModel[]): UserViewModel[][] => shifts.map((shift) => {
    const shiftsThatOverlapWithThisShift = shifts.filter((otherShift) => {
        if (shift.id === otherShift.id) {
            return false;
        }

        try {
            return areIntervalsOverlapping({
                start: new Date(shift.start),
                end: new Date(shift.end.date),
            }, {
                start: new Date(otherShift.start),
                end: new Date(otherShift.end.date),
            });
        } catch (e) {
            console.error('Error while checking if shifts overlap. Shifts:', shift, otherShift, 'Exception:', e);
        }

        return false;
    });

    // No overlapping shifts, return empty array
    if (!shiftsThatOverlapWithThisShift.length) {
        return [];
    }

    const usersInThisShift = shift.shiftPlannings.map(shiftPlanning => shiftPlanning.user);
    const usersInThisShiftThatAlsoExistInShiftsThatOverlapWithThisShift = usersInThisShift
        .filter(user => shiftsThatOverlapWithThisShift
            .some(otherShift => otherShift.shiftPlannings
                .some(otherShiftPlanning => otherShiftPlanning.user?.id === user?.id)));

    return usersInThisShiftThatAlsoExistInShiftsThatOverlapWithThisShift
        .filter((user => user !== undefined)) as UserViewModel[];
});
