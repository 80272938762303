import React, { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useEffectOnce, useLocation } from 'react-use';

import { PrivateRouteGroup } from './@paco/compositions';
import Layout from './@paco/containers/Layout/Layout';
import { localStorageTokenKey } from './@paco/helpers/authorizedFetch';
import { useIdleTimer } from './@paco/helpers/hooks/useIdleTimer';
import { InactiveUserPage, PincodePage } from './@paco/pages';
import { getProfileOfCurrentUser } from './@paco/redux/authenticatedUser/authenticatedUserActions';
import { setError } from './@paco/redux/authenticatedUser/authenticatedUserReducer';
import useContractHours from './@paco/redux/contractHours/contractHoursHooks';
import { UserStatus } from './@paco/types/userStatus';
import CacheBuster from './components/CacheBuster/CacheBuster';
import { addModelCloseListeners, isTouch } from './helpers';
import { translate } from './helpers/translations/translator';
import ErrorPage from './pages/ErrorPage';
import ForgotPassword from './pages/Login/ForgotPassword';
import Login from './pages/Login/Login';
import {
    logout,
    setAppInit,
    setAppNotificationsInterval,
    setAppRouteChange,
} from './redux/app/appActions';
import { Reducers } from './redux/reducers';
import { useTypedDispatch, useTypedSelector } from './redux/store';

const App: FC = () => {
    const dispatch = useDispatch();
    const pacoDispatch = useTypedDispatch();
    const location = useLocation();
    useContractHours();
    useIdleTimer();
    const apiToken = localStorage.getItem(localStorageTokenKey);
    const appError = useSelector<Reducers, any>(state => state.appReducer.error);
    const {
        permittedUserRoutes,
        user,
        isUserIdle,
        error: authenticatedUserError,
    } = useTypedSelector(state => state.authenticatedUserReducer);

    const isAuthenticated = !!apiToken;
    const isUserInactive = user?.status === UserStatus.inActive || user?.status === UserStatus.deleted;
    const loginPath = `/${translate('nav.login.link')}`;
    const forgotPasswordPath = `/${translate('nav.forgotPassword.link')}`;
    const appErrorCode = appError && parseInt(appError.code, 10);

    useEffectOnce((): void => {
        document.body.classList.add(isTouch() ? 'is-touch' : 'no-touch');
        addModelCloseListeners();
    });

    useEffect((): void => {
        if (apiToken) {
            dispatch(setAppInit());
            dispatch(setAppNotificationsInterval(dispatch));
            pacoDispatch(getProfileOfCurrentUser());
        }
    }, [apiToken]);

    useEffect((): void => {
        dispatch(setAppRouteChange());
    }, [location.pathname]);

    useEffect((): void => {
        if (authenticatedUserError) {
            dispatch(logout());
            dispatch(setError(''));
        }
    }, [authenticatedUserError]);

    useEffect((): void => {
        if (user && !user.permissions.some(permission => permission.slug === 'use-manager-app')) {
            dispatch(logout());
        }
    }, [user]);

    if (isAuthenticated && isUserInactive) {
        return (
            <InactiveUserPage />
        );
    }

    if (isAuthenticated && isUserIdle) {
        return (
            <PincodePage />
        );
    }

    if ((appErrorCode === 500) || (appErrorCode === 410)) {
        return (
            <Layout>
                <ErrorPage errorCode={appErrorCode} />
            </Layout>
        );
    }

    return (
        <CacheBuster>
            <Routes>
                {isAuthenticated ? (
                    <Route path="/*" element={<PrivateRouteGroup permittedUserRoutes={permittedUserRoutes} />} />
                ) : (
                    <>
                        <Route path="/" element={<Login />} />
                        <Route path={loginPath} element={<Login />} />
                        <Route path={forgotPasswordPath} element={<ForgotPassword />} />
                        <Route path="/*" element={<Navigate to="/" />} />
                    </>
                )}
            </Routes>
        </CacheBuster>
    );
};

export default App;
