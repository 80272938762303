import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import classnames from 'classnames';

import { ConnectedHelpTooltip } from '../../../../@paco/connectors';
import trans from '../../../../@paco/helpers/trans';
import { TimeModeType } from '../../../../@paco/types';
import { BaseScheduleShiftViewModel } from '../../../../entities/BaseScheduleShift/BaseScheduleShift';
import { ShiftConceptViewModelOld } from '../../../../entities/ShiftConcept/ShiftConcept';
import { UnavailableToWorkTimeSlotViewModel } from '../../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import { scrollToElementAndCenter } from '../../../../helpers';
import useKeyPress from '../../../../helpers/hooks/useKeypress';
import {
    AbsenceViewModel,
    LeaveOfAbsenceViewModel,
    ShiftDraggable,
    ShiftType,
    ShiftViewModel,
    SpecialDayViewModel,
} from '../../../../models';
import transformShiftsToShiftDraggable from '../../../../services/ShiftConceptService/transformShiftsToShiftDraggable';
import transformShiftsToTransposedShiftDraggable
    from '../../../../services/ShiftConceptService/transformShiftsToTransposedShiftDraggable';
import ConceptDays from '../../components/ConceptDays/ConceptDays';
import didUserClickShiftOrModalOrDeleteButton from './helpers/didUserClickShiftOrModalOrDeleteButton';
import getMultiSelectedShiftIds from './helpers/getMultiSelectedShiftIds';
import getShiftsByIdAndShiftType from './helpers/getShiftsByIdAndShiftType';

import './ConceptsCalendar.scss';

interface ConceptsCalendarProps {
    canEditShifts?: boolean;
    canEditShiftConcepts?: boolean;
    canEditBaseScheduleShifts?: boolean;
    className?: string;
    showAddButton?: boolean;
    showAddConceptButton?: boolean;
    showDayInMonth?: boolean;
    dayHeaderFormat?: 'EEEEEE' | 'EEEE';
    hasDarkBackground?: boolean;
    hasMultiSelect?: boolean;
    showEmploymentTypeBadge?: boolean;
    showOverlappingShiftsWarnings?: boolean;
    showEmployees?: boolean;
    deleteButtonId?: string;
    highlightShiftId?: string;
    isMobile?: boolean;
    timeMode?: TimeModeType;
    shifts: (ShiftViewModel | ShiftConceptViewModelOld | BaseScheduleShiftViewModel)[];
    specialDays?: SpecialDayViewModel[];
    leaveOfAbsences?: LeaveOfAbsenceViewModel[];
    absences?: AbsenceViewModel[];
    unavailableTimeSlots?: UnavailableToWorkTimeSlotViewModel[];
    selectedDays: Date[];
    onShiftClick: (shift: ShiftDraggable) => void;
    onAddButtonClick?: (day: Date) => void;
    onAddBaseScheduleButtonClick?: (day: Date) => void;
    onSelectedShiftsChange?: (shifts: string[]) => void;
    onDeleteShifts?: (shifts: string[]) => void;
    onCopyShifts?: (shifts: string[], dateOffset: number) => void;
}

const ConceptsCalendar:FC<ConceptsCalendarProps> = ({
    className,
    showAddButton = false,
    showAddConceptButton = false,
    showDayInMonth = false,
    hasDarkBackground = false,
    hasMultiSelect = false,
    canEditShifts = false,
    canEditShiftConcepts = false,
    canEditBaseScheduleShifts = false,
    showEmploymentTypeBadge = false,
    showOverlappingShiftsWarnings = false,
    showEmployees = false,
    dayHeaderFormat = 'EEEEEE',
    deleteButtonId,
    highlightShiftId,
    isMobile,
    timeMode,
    shifts,
    selectedDays,
    specialDays = [],
    leaveOfAbsences = [],
    unavailableTimeSlots = [],
    absences = [],
    onShiftClick,
    onSelectedShiftsChange,
    onAddButtonClick,
    onAddBaseScheduleButtonClick,
    onDeleteShifts,
    onCopyShifts,
}) => {
    const [selectedShiftIds, setSelectedShiftIds] = useState<string[]>([]);
    const [draggingShiftId, setDraggingShiftId] = useState<string | null>(null);
    const [selectedCopyDate, setSelectedCopyDate] = useState<Date | null>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const shiftPress = useKeyPress(16);
    const leftCtrlPress = useKeyPress(17);
    const leftAltPress = useKeyPress(18);
    const cmdPress = useKeyPress(93);
    const singleSelectModeIsActive = hasMultiSelect && (leftCtrlPress || leftAltPress || cmdPress);
    const multiSelectModeIsActive = hasMultiSelect && shiftPress;

    const classNames = useMemo(() => classnames('concepts-calendar', {
        'concepts-calendar--has-dark-background': hasDarkBackground,
        'concepts-calendar--has-single-select-mode-active': singleSelectModeIsActive,
        'concepts-calendar--is-dragging': !!draggingShiftId,
    }, className), [
        className,
        hasDarkBackground,
        draggingShiftId,
        leftCtrlPress,
        leftAltPress,
        cmdPress,
    ]);

    const [draggableShifts] = useMemo(
        () => (
            transformShiftsToShiftDraggable(
                shifts,
                draggingShiftId,
                selectedShiftIds,
                selectedDays,
                selectedCopyDate,
                new Date(),
            )),
        [
            shifts,
            draggingShiftId,
            selectedShiftIds,
            selectedDays,
            selectedCopyDate,
        ],
    );

    const clearSelections = () => {
        setDraggingShiftId(null);
        setSelectedCopyDate(null);
        setSelectedShiftIds([]);
    };

    const toggleSelectedShiftId = (shiftId: string, shiftType: ShiftType) => {
        if (selectedShiftIds.includes(shiftId)) {
            setSelectedShiftIds(selectedShiftIds.filter(id => id !== shiftId));
        } else if (hasMultiSelect) {
            const ids = getShiftsByIdAndShiftType(selectedShiftIds, shifts, shiftType);
            setSelectedShiftIds([...ids, shiftId]);
        } else {
            setSelectedShiftIds([shiftId]);
        }
    };

    const handleDayDragEnter = (date?: Date) => {
        setSelectedCopyDate(date || null);
    };

    const handleShiftClick = (shift: ShiftDraggable) => {
        if (!singleSelectModeIsActive && !multiSelectModeIsActive) {
            onShiftClick(shift);
        }

        if (multiSelectModeIsActive && shift.type === ShiftType.concept) {
            const newIds = getMultiSelectedShiftIds(
                selectedShiftIds,
                shift.id,
                shifts,
            );
            setSelectedShiftIds(newIds);
        }

        if (singleSelectModeIsActive && shift.type === ShiftType.concept) {
            toggleSelectedShiftId(shift.id, shift.type);
        }
    };

    const handleShiftStartDrag = (shift: ShiftDraggable) => {
        setDraggingShiftId(shift.id);
        setIsDragging(true);

        if (!hasMultiSelect || shift.type !== ShiftType.concept) {
            setSelectedShiftIds([shift.id]);
            return;
        }

        if (!selectedShiftIds.includes(shift.id)) {
            const ids = getShiftsByIdAndShiftType(selectedShiftIds, shifts, shift.type);
            const newIds = [...ids, shift.id];
            setSelectedShiftIds(newIds);
        }
    };

    const handleDayDrop = () => {
        setIsDragging(false);
        if (!onCopyShifts) {
            return;
        }

        const selectedShifts = shifts.filter((shift) => selectedShiftIds.includes(shift.id));
        const [previewShiftConcepts, dateOffset] = transformShiftsToTransposedShiftDraggable(
            draggingShiftId,
            selectedShifts,
            selectedCopyDate,
            selectedDays,
            new Date(),
        );

        if (previewShiftConcepts.length) {
            onCopyShifts(previewShiftConcepts.map(shift => shift.id), dateOffset);
        }
    };

    const handleAddButtonClick = (date: Date) => {
        clearSelections();
        if (onAddButtonClick) {
            onAddButtonClick(date);
        }
    };

    const handleAddConceptButtonClick = (date: Date) => {
        clearSelections();
        if (onAddBaseScheduleButtonClick) {
            onAddBaseScheduleButtonClick(date);
        }
    };

    const handleWindowClick = useCallback((event: any) => {
        if (!didUserClickShiftOrModalOrDeleteButton(event, deleteButtonId)) {
            clearSelections();
        }
    }, []);

    const handleDeleteButtonDrop = useCallback((shiftIdsToDelete: string[]) => {
        if (onDeleteShifts) {
            onDeleteShifts(shiftIdsToDelete);
        }
    }, [selectedShiftIds]);


    const handleDeleteButtonDragEnter = useCallback(() => {
        setSelectedCopyDate(null);
    }, []);

    useEffect(() => {
        clearSelections();
    }, [shifts.length]);

    useEffect(() => {
        window.addEventListener('click', handleWindowClick);
        return () => {
            window.removeEventListener('click', handleWindowClick);
        };
    }, [handleWindowClick]);

    useEffect(() => {
        const deleteButton = document.getElementById(deleteButtonId || '');

        if (deleteButton) {
            deleteButton.ondrop = () => handleDeleteButtonDrop(selectedShiftIds);
            deleteButton.ondragenter = handleDeleteButtonDragEnter;
        }
    }, [deleteButtonId, selectedShiftIds]);

    useEffect(() => {
        setSelectedCopyDate(null);
        if (onSelectedShiftsChange) {
            onSelectedShiftsChange(selectedShiftIds);
        }
    }, [selectedShiftIds]);

    useEffect(() => {
        const element = document.getElementById(`concept-shift-${highlightShiftId}`);
        if (element) {
            scrollToElementAndCenter(element);
        }
    }, [highlightShiftId]);

    return (
        <div className={classNames}>
            <ConceptDays
                className="concepts-calendar__days"
                dayClassName="concepts-calendar__day"
                dayButtonsClassName="concepts-calendar__day-buttons"
                dayHeaderClassName="concepts-calendar__day-header"
                dayContentClassName="concepts-calendar__day-content"
                dayContentHeaderLabelClassName="concepts-calendar__day-content-header-label"
                dayContentHeaderDayClassName="concepts-calendar__day-content-header-day"
                shiftClassName="concepts-calendar__concept-shift"
                isDragging={isDragging}
                isMobile={isMobile}
                canEditShifts={canEditShifts}
                canEditShiftConcepts={canEditShiftConcepts}
                canEditBaseScheduleShifts={canEditBaseScheduleShifts}
                showAddButton={showAddButton}
                showAddConceptButton={showAddConceptButton}
                showDayInMonth={showDayInMonth}
                showEmploymentTypeBadge={showEmploymentTypeBadge}
                showOverlappingShiftsWarnings={showOverlappingShiftsWarnings}
                showEmployees={showEmployees}
                timeMode={timeMode}
                dayHeaderFormat={dayHeaderFormat}
                highlightShiftId={highlightShiftId}
                shifts={draggableShifts}
                specialDays={specialDays}
                selectedDays={selectedDays}
                leaveOfAbsences={leaveOfAbsences}
                absences={absences}
                unavailableTimeSlots={unavailableTimeSlots}
                onAddButtonClick={handleAddButtonClick}
                onAddBaseScheduleButtonClick={handleAddConceptButtonClick}
                onShiftClick={handleShiftClick}
                onDayDragEnter={handleDayDragEnter}
                onDayDrop={handleDayDrop}
                onShiftStartDrag={handleShiftStartDrag}
            />
            <ConnectedHelpTooltip
                index={0}
                route="calendar"
                subTitle={trans('help.conceptsCalendar.addBaseSchedule.title')}
                text={trans('help.conceptsCalendar.addBaseSchedule.text')}
                title={trans('help.conceptsCalendar.title')}
                showMobileInfoWarning
                className="concepts-calendar__add-base-schedule-help-tooltip"
            />
            <ConnectedHelpTooltip
                index={1}
                route="calendar"
                subTitle={trans('help.conceptsCalendar.addConceptShift.title')}
                text={trans('help.conceptsCalendar.addConceptShift.text')}
                title={trans('help.conceptsCalendar.title')}
                className="concepts-calendar__add-concept-shift-help-tooltip"
            />
            <ConnectedHelpTooltip
                index={2}
                route="calendar"
                subTitle={trans('help.conceptsCalendar.filters.title')}
                text={trans('help.conceptsCalendar.filters.text')}
                title={trans('help.conceptsCalendar.title')}
                showMobileInfoWarning
                className="shifts-calendar__filters-help-tooltip"
            />
        </div>
    );
};

export default ConceptsCalendar;
