import { FC, ReactElement } from 'react';

import { ConnectedPincode } from '../../connectors';
import { LoginLayout } from '../../containers';

interface PincodePageProps {
    className?: string;
}

const PincodePage: FC<PincodePageProps> = (): ReactElement => (
    <LoginLayout>
        <ConnectedPincode />
    </LoginLayout>
);

export default PincodePage;
