import { useEffect } from 'react';

import { useTypedSelector } from '../../../redux/store';
import { setIsUserIdle } from '../../redux/authenticatedUser/authenticatedUserReducer';
import { useTypedDispatch } from '../../redux/store';

// TODO: Make this configurable if needed
const idleTimeout = 15 * 60 * 1000; // 15 minutes in milliseconds

let idleTimer: ReturnType<typeof setTimeout>;

export const useIdleTimer = (): ReturnType<typeof setTimeout> => {
    const dispatch = useTypedDispatch();
    const { isUserIdle } = useTypedSelector(state => state.authenticatedUserReducer);

    const resetIdleTimer = () => {
        clearTimeout(idleTimer);
        idleTimer = setTimeout(() => {
            dispatch(setIsUserIdle(true));
        }, idleTimeout);
    };

    useEffect(() => {
        if (!isUserIdle) {
            resetIdleTimer();
        }
    }, [isUserIdle]);

    useEffect(() => {
        const events = ['mousemove', 'keydown', 'scroll', 'click'];

        events.forEach(event => {
            window.addEventListener(event, resetIdleTimer);
        });

        return () => {
            clearTimeout(idleTimer);
            events.forEach(event => {
                window.removeEventListener(event, resetIdleTimer);
            });
        };
    }, []);

    return idleTimer;
};
