import { getUniqueArrayList } from '../../@paco/helpers/array';
import { PzLeaveType } from '../../@paco/types/leaveType';
import {
    clearLeaveOfAbsenceHours,
    deleteAbsenceHours,
    deleteLeaveOfAbsenceHours,
    patchLeaveOfAbsenceHours,
    postLeaveOfAbsenceHours,
} from '../../helpers/api/absencesApi';
import { clearAbsenceHours, patchAbsenceHours, postAbsenceHour } from '../../helpers/api-ts/absenceHour';
import UpToButExcludingDate from '../../helpers/date/UpToButExcludingDate';
import {
    AbsenceHours,
    LeaveOfAbsence,
    LeaveOfAbsenceHours,
    LeaveType,
    WeekWithHours,
    WeekWithHoursAndWaitingDayHours,
} from '../../models';
import transformAbsenceHoursToPatchAbsenceHourData from '../../services/AbsenceHoursService/transformAbsenceHoursToPatchAbsenceHourData';

export function deductOneSecondFromLOARequests(leaveOfAbsences: LeaveOfAbsence[] = []) {
    // TODO: Integrate UpToAndIncludingDate and UpToButExcludingDate in leaveOfAbsences data models.
    return leaveOfAbsences.map(loa => ({
        ...loa,
        end: new UpToButExcludingDate(loa.end).transformToUpToAndIncludingDate().date,
        start: new Date(loa.start),
    }));
}

function doesAbsenceHoursAndWeekdayMatch(
    absenceHours: AbsenceHours | LeaveOfAbsenceHours,
    weekday: WeekWithHours,
): boolean {
    return absenceHours.weekNumber === weekday.weekNumber
        && absenceHours.payrollPeriod.year === (weekday.payrollPeriod
            && weekday.payrollPeriod.year);
}

function getPatchLeaveOfAbsenceHours(
    leaveOfAbsenceId: string,
    leaveOfAbsenceHour: LeaveOfAbsenceHours,
    weeksWithHours: WeekWithHours[],
    leaveType: LeaveType | PzLeaveType,
) {
    const weekday = weeksWithHours
        .find(weekdayWithHours => doesAbsenceHoursAndWeekdayMatch(leaveOfAbsenceHour, weekdayWithHours));

    return patchLeaveOfAbsenceHours(
        leaveOfAbsenceHour.id,
        leaveOfAbsenceId,
        leaveOfAbsenceHour.payrollPeriod.id,
        weekday ? weekday.hours : 0,
        weekday ? weekday.weekNumber : 0,
        leaveType,
    );
}

export function getHoursPatchClearAndPostData(
    // Current AbsenceHours or LeaveOfAbsence linked to a user's Absence or LeaveOfAbsence
    absenceHours: (AbsenceHours | LeaveOfAbsenceHours)[],
    // New WeekWithHours coming from the WeekdayHoursInputs component
    newWeekdays: (WeekWithHours | WeekWithHoursAndWaitingDayHours)[],
): {
        toPatch: (AbsenceHours | LeaveOfAbsenceHours)[];
        toClear: string[];
        toDelete: string[];
        toPost: (WeekWithHours | WeekWithHoursAndWaitingDayHours)[];
    } {
    // This is a fix for CREA-745: on production there is a possibility for multiple absenceHours with the same weekNumber.
    const uniqueAbsenceHours: (AbsenceHours | LeaveOfAbsenceHours)[] = getUniqueArrayList(absenceHours, 'weekNumber');
    const duplicatedAbsenceHours = absenceHours.filter(absenceHour => !uniqueAbsenceHours.some(uniqueAbsenceHour => absenceHour.id === uniqueAbsenceHour.id));

    const hoursToPatch = uniqueAbsenceHours
        .filter(absenceHour => newWeekdays
            .some(weekday => doesAbsenceHoursAndWeekdayMatch(absenceHour, weekday)));
    const hoursOutsideOfWeekdays = absenceHours
        .filter(absenceHour => !newWeekdays
            .some(weekday => doesAbsenceHoursAndWeekdayMatch(absenceHour, weekday)));
    const hoursToClearOrDelete: (AbsenceHours | LeaveOfAbsenceHours)[] = getUniqueArrayList([...hoursOutsideOfWeekdays, ...duplicatedAbsenceHours], 'id');
    const hoursToClear = hoursToClearOrDelete.filter(absenceHour => absenceHour.type === 'leaveOfAbsenceHours' && absenceHour.loketIds).map(absenceHour => absenceHour.id);
    const hoursToDelete = hoursToClearOrDelete
        .filter(hourToClearOrDelete => !hoursToClear
            .some(uniqueHourToClearOrDeleteId => hourToClearOrDelete.id === uniqueHourToClearOrDeleteId))
        .map(absenceHour => absenceHour.id);
    const hoursToPost = newWeekdays
        .filter(weekday => !hoursToPatch
            .some(absenceHour => doesAbsenceHoursAndWeekdayMatch(absenceHour, weekday)))
        .filter(weekday => weekday.payrollPeriod?.locked !== true);

    return {
        toPatch: hoursToPatch,
        toClear: hoursToClear,
        toDelete: hoursToDelete,
        toPost: hoursToPost,
    };
}

export async function updateLeaveOfAbsenceHours(
    leaveOfAbsenceId: string,
    leaveOfAbsenceHours: LeaveOfAbsenceHours[],
    weekdayWithHours: WeekWithHours[],
    leaveType: LeaveType | PzLeaveType,
) {
    const data = getHoursPatchClearAndPostData(leaveOfAbsenceHours, weekdayWithHours);
    const absenceHoursToPatch = data.toPatch as LeaveOfAbsenceHours[];
    const absenceHoursToPost = data.toPost as WeekWithHours[];
    const absenceHoursToClear = [...data.toClear, ...data.toDelete];

    return Promise.all([
        ...absenceHoursToPatch.map(
            leaveOfAbsenceHour => getPatchLeaveOfAbsenceHours(
                leaveOfAbsenceId,
                leaveOfAbsenceHour,
                weekdayWithHours,
                leaveType,
            ),
        ),
        ...absenceHoursToClear.map(leaveOfAbsenceHourId => clearLeaveOfAbsenceHours(leaveOfAbsenceHourId)),
        ...data.toDelete.map(leaveOfAbsenceHourId => deleteLeaveOfAbsenceHours(leaveOfAbsenceHourId)),
        ...absenceHoursToPost.map(
            weekday => postLeaveOfAbsenceHours(
                leaveOfAbsenceId,
                weekday.payrollPeriod ? weekday.payrollPeriod.id : undefined,
                weekday.hours,
                weekday.weekNumber,
                leaveType,
            ),
        ),
    ]);
}

export async function updateAbsenceHours(
    absenceId: string,
    absenceHours: AbsenceHours[],
    weekdaysWithHours: WeekWithHours[],
    waitingDayHours: number | null,
) {
    if (!weekdaysWithHours.length) {
        return Promise.resolve();
    }

    const weekdays: WeekWithHoursAndWaitingDayHours[] = weekdaysWithHours.map((weekday, index) => ({
        ...weekday,
        waitingDayHours: index === 0 ? (waitingDayHours || 0) : 0,
    }));

    const data = getHoursPatchClearAndPostData(absenceHours, weekdays);
    const absenceHoursToPatch = data.toPatch as AbsenceHours[];
    const absenceHoursToPost = data.toPost as WeekWithHoursAndWaitingDayHours[];
    const absenceHoursToClear = [...data.toClear, ...data.toDelete];

    await Promise.all([
        patchAbsenceHours(
            transformAbsenceHoursToPatchAbsenceHourData(
                absenceId,
                absenceHoursToPatch,
                weekdays,
            ),
        ),
        clearAbsenceHours(absenceHoursToClear),
        ...data.toDelete.map(absenceHourId => deleteAbsenceHours(absenceHourId)),
        absenceHoursToPost.map(
            weekday => postAbsenceHour(
                absenceId,
                weekday.payrollPeriod ? weekday.payrollPeriod.id : '',
                weekday.hours,
                weekday.weekNumber,
                weekday.waitingDayHours,
            ),
        ),
    ]);

    return Promise.resolve();
}
