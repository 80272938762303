import { FC, ReactElement, useEffect } from 'react';

import { KeyboardEventKey } from 'keyboard-event-key-type';

import useKeyUp from '../../helpers/hooks/useKeyUp';
import { NumpadFunctionType } from '../../types';
import { NumpadButton } from './subcomponents';

import './NumpadInput.scss';

interface NumpadInputProps {
    onButtonKeyUp: (value: number | NumpadFunctionType) => void;
    className?: string;
}

const enterKey: KeyboardEventKey = 'Enter';
const backspaceKey: KeyboardEventKey = 'Backspace';

const NumpadInput: FC<NumpadInputProps> = ({
    onButtonKeyUp,
    className = '',
}): ReactElement => {
    const key = useKeyUp();

    const buttons = [
        1, 2, 3,
        4, 5, 6,
        7, 8, 9,
        NumpadFunctionType.backspace,
        0,
    ];

    useEffect((): void => {
        if (!key) {
            return;
        }

        if (key === enterKey) {
            onButtonKeyUp(NumpadFunctionType.submit);

            return;
        }

        if (key === backspaceKey) {
            onButtonKeyUp(NumpadFunctionType.backspace);

            return;
        }

        const parsedNumber = parseInt(key, 10);

        if (!Number.isNaN(parsedNumber)) {
            onButtonKeyUp(parsedNumber);
        }
    }, [key]);

    return (
        <div className={`numpad-input ${className}`}>
            {buttons.map(value => (
                <NumpadButton
                    key={value}
                    value={value}
                    onKeyUp={onButtonKeyUp}
                    className="numpad-input__button"
                />
            ))}
        </div>
    );
};

export default NumpadInput;
