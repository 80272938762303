import { isFetchCollectionResultSuccessful } from '../../entities/FetchResult';
import { SpecialDay } from '../../entities/SpecialDay/SpecialDay';
import { getSpecialDayIndexCall } from '../../entities/SpecialDay/SpecialDayService';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setSpecialDays } from './specialDaysReducer';

const calledDates = new Map<string, SpecialDay[]>();

export const fetchSpecialDays = (
    startDate: Date,
    endDate: Date,
) => async (dispatch: TypedDispatch): Promise<void> => {
    // Only fetch once for the same date range
    const dateKey = `${startDate.toISOString()}_${endDate.toISOString()}`;
    if (calledDates.has(dateKey)) {
        dispatch(setSpecialDays(calledDates.get(dateKey) || []));
        return;
    }

    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const response = await getSpecialDayIndexCall(startDate, endDate);

        if (!isFetchCollectionResultSuccessful(response)) {
            dispatch(setError(response.error));
            return;
        }

        const specialDays = response.data;
        calledDates.set(dateKey, specialDays);
        dispatch(setSpecialDays(specialDays));
    } catch (error) {
        console.error('[fetchSpecialDays]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
