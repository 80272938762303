import axios from 'axios';

import { DateFormat } from '../../@paco/types/dateFormatTypes';
import {
    ABSENCES,
    ABSENCES_OVERVIEW,
    AVAILABILITY_CALENDAR,
    LEAVE_OF_ABSENCE_REQUESTS,
    LEAVE_OF_ABSENCE_REQUESTS_CALENDAR,
    LIST_VIEW,
    PAGE_SIZES,
    PZ_COMPLETE_PERIOD,
    TRACKS_FINISHED,
    TYPE_ABSENCE,
    TYPE_LEAVE_OF_ABSENCE,
} from '../../constants';
import {
    addDays,
    convertDateToApiParamDateTime,
    formatDate,
    getEndOfWorkDay,
    getStartOfWorkDay,
} from '../date';
import UpToAndIncludingDate from '../date/UpToAndIncludingDate';
import { getLoketApiUrl } from '../index';

export const getLeaveOfAbsenceRequestIncludes = (page) => {
    switch (page) {
        case LEAVE_OF_ABSENCE_REQUESTS:
            return [
                'user',
                'user.experience',
                'user.roles',
                'overlappingLeaveOfAbsences',
                'comments',
                'comments.owner.person',
                'comments.owner.roles',
                'reviewedByUser',
                'reviewedByUser.roles',
                'leaveOfAbsenceHours',
                'leaveOfAbsenceHours.payrollPeriod',
            ];
        case PZ_COMPLETE_PERIOD:
            return [
                'user',
                'user.experience',
                'user.mainDepartment',
                'user.roles',
                'user.employmentType',
                'comments',
                'comments.owner.person',
                'comments.owner.roles',
                'reviewedByUser',
                'reviewedByUser.roles',
                'leaveOfAbsenceHours',
                'leaveOfAbsenceHours.payrollPeriod',
            ];
        case AVAILABILITY_CALENDAR:
        case LIST_VIEW:
            return [
                'user',
                'user.experience',
                'user.roles',
                'comments',
                'comments.owner.person',
                'comments.owner.roles',
                'reviewedByUser',
                'reviewedByUser.roles',
                'leaveOfAbsenceHours',
                'leaveOfAbsenceHours.payrollPeriod',
            ];
        case LEAVE_OF_ABSENCE_REQUESTS_CALENDAR:
            return [
                'user',
                'user.experience',
                'user.roles',
                'comments',
                'comments.owner.person',
                'comments.owner.roles',
                'reviewedByUser',
                'reviewedByUser.roles',
                'leaveOfAbsenceHours',
                'leaveOfAbsenceHours.payrollPeriod',
            ];
        case TRACKS_FINISHED:
            return ['user', 'leaveOfAbsenceHours'];
        default:
            return ['user'];
    }
};

const getLeaveOfAbsences = (
    startDate,
    endDate,
    status,
    departments = [],
    filter,
    page,
    pagination,
) => {
    const includes = getLeaveOfAbsenceRequestIncludes(page);
    const size = PAGE_SIZES.ABSENCES[page];
    const {
        userTypes,
        employeeSearch,
        onlyShowMainDepartment,
        employeeContractTypes,
    } = filter || {};

    const givenStartDate = page !== LEAVE_OF_ABSENCE_REQUESTS ? startDate : addDays(new Date(), -1);
    const givenEndDate = page !== LEAVE_OF_ABSENCE_REQUESTS ? endDate : undefined;

    const formattedStartDate = formatDate(getStartOfWorkDay(givenStartDate), DateFormat.apiDateTime);
    const formattedEndDate = givenEndDate ? formatDate(getEndOfWorkDay(givenEndDate), DateFormat.apiDateTime) : undefined;

    return axios.get(
        `${process.env.REACT_APP_API_URL}/leave-of-absences/`,
        {
            params: {
                include: includes.join(','),
                ...(employeeSearch && { search: employeeSearch }),
                departments: onlyShowMainDepartment ? [] : departments,
                mainDepartments: onlyShowMainDepartment ? departments : [],
                onlyShowMatchingDepartments: departments.length > 0,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                statuses: JSON.stringify(status),
                roles: (userTypes && userTypes.length > 0) ? JSON.stringify(userTypes) : undefined,
                employmentTypes: (employeeContractTypes && employeeContractTypes.length > 0) ? JSON.stringify(employeeContractTypes) : undefined,
                ...(pagination && { 'page[number]': pagination.number || 1 }),
                ...(size && { 'page[size]': size }),
            },
        },
    );
};

const getAbsences = (filter, departments, pagination) => {
    const {
        employeeSearch,
        userTypes,
        employeeContractTypes,
    } = filter;
    const includes = ['user', 'user.experience', 'user.roles', 'comments', 'overlappingShifts', 'overlappingShifts.department',
        'overlappingShifts.plannedUsers', 'overlappingShifts.plannedUsers.roles', 'overlappingShifts.plannedUsers.employmentType', 'absenceHours', 'absenceHours.payrollPeriod'];
    const size = PAGE_SIZES.ABSENCES[ABSENCES];

    return axios.get(
        `${process.env.REACT_APP_API_URL}/absences/`,
        {
            params: {
                include: includes.join(','),
                search: employeeSearch,
                departments,
                roles: (userTypes && userTypes.length > 0) ? JSON.stringify(userTypes) : undefined,
                showOpenAbsencesOnly: true,
                employmentTypes: (employeeContractTypes && employeeContractTypes.length > 0) ? JSON.stringify(employeeContractTypes) : undefined,
                'page[number]': pagination.number || 1,
                'page[size]': size,
            },
        },
    );
};

const getAbsencesInDateRange = (
    startDate,
    endDate,
    filter,
    departments,
    pagination,
    includes = ['user', 'user.roles', 'absenceHours', 'absenceHours.payrollPeriod'],
) => {
    const size = pagination && PAGE_SIZES.ABSENCES[ABSENCES_OVERVIEW];
    const { userTypes, employeeContractTypes } = filter || {};

    return axios.get(
        `${process.env.REACT_APP_API_URL}/absences/`,
        {
            params: {
                include: includes.join(','),
                ...(filter && { search: filter.employeeSearch }),
                ...(departments && { departments }),
                ...(pagination && { 'page[number]': pagination.number || 1 }),
                ...(size && { 'page[size]': size }),
                startDate: formatDate(getStartOfWorkDay(startDate), DateFormat.apiDateTime),
                endDate: formatDate(getEndOfWorkDay(endDate), DateFormat.apiDateTime),
                roles: (userTypes && userTypes.length > 0) ? JSON.stringify(userTypes) : undefined,
                employmentTypes: (employeeContractTypes && employeeContractTypes.length > 0) ? JSON.stringify(employeeContractTypes) : undefined,
            },
        },
    );
};

const getAbsencesInDateRangeCSV = (
    startDate,
    endDate,
    departmentIds,
    filter,
) => {
    const { userTypes } = filter;

    return axios.get(
        `${process.env.REACT_APP_API_URL}/absences/csv`,
        {
            params: {
                search: filter.employeeSearch,
                departments: departmentIds,
                startDate: formatDate(getStartOfWorkDay(startDate), DateFormat.apiDateTime),
                endDate: formatDate(getEndOfWorkDay(endDate), DateFormat.apiDateTime),
                roles: (userTypes && userTypes.length > 0) ? JSON.stringify(userTypes) : undefined,
            },
        },
    );
};

const postLeaveOfAbsenceAccept = (leaveOfAbsenceId, data) => {
    if (!data) {
        return axios.post(`${process.env.REACT_APP_API_URL}/leave-of-absences/${leaveOfAbsenceId}/approve`);
    }

    const newData = {
        ...data,
        end: data.end ? formatDate(data.end, DateFormat.apiDateTime) : data.end,
    };

    return axios.post(`${process.env.REACT_APP_API_URL}/leave-of-absences/${leaveOfAbsenceId}/approve`, {
        data: {
            uuid: leaveOfAbsenceId,
            type: 'leaveOfAbsences',
            attributes: newData,
        },
    });
};

const postLeaveOfAbsenceDecline = id => axios.post(
    `${process.env.REACT_APP_API_URL}/leave-of-absences/${id}/decline`,
);

const postExchangeLink = (exchangeId, userId) => axios.post(
    `${process.env.REACT_APP_API_URL}/exchanges/${exchangeId}/link/${userId}/`,
);

export const deleteExchange = (exchangeId) => axios.delete(
    `${process.env.REACT_APP_API_URL}/exchanges/${exchangeId}`,
);

const patchAbsence = (
    id,
    startDate = null,
    endDate = null,
) => {
    const body = {
        id,
        type: 'absences',
        attributes: {
            start: startDate,
            ...(endDate && { end: endDate }),
        },
    };
    return axios.patch(
        `${process.env.REACT_APP_API_URL}/absences/${id}/`,
        { data: body },
    );
};

const postAbsence = (startDate, userId) => {
    const body = {
        type: 'absences',
        attributes: {
            start: startDate,
        },
        relationships: {
            user: {
                data: {
                    type: 'users',
                    id: userId,
                },
            },
        },
    };
    return axios.post(
        `${process.env.REACT_APP_API_URL}/absences/`,
        { data: body },
    );
};

const deleteAbsence = absenceId => axios.delete(
    `${process.env.REACT_APP_API_URL}/absences/${absenceId}/`,
);

const patchLeaveOfAbsence = (leaveOfAbsenceId, data) => {
    const newData = { ...data };

    // TODO: Integrate UpToAndIncludingDate and UpToButExcludingDate in leaveOfAbsences data models.
    newData.end = data.end ? convertDateToApiParamDateTime(
        new UpToAndIncludingDate(data.end).transformToUpToButExcludingDate().date,
    ) : data.end;

    const body = {
        id: leaveOfAbsenceId,
        type: 'leaveOfAbsences',
        attributes: newData,
    };
    return axios.patch(
        `${process.env.REACT_APP_API_URL}/leave-of-absences/${leaveOfAbsenceId}`,
        { data: body },
    );
};

const postLeaveOfAbsence = (data, userId) => {
    // TODO: Integrate UpToAndIncludingDate and UpToButExcludingDate in leaveOfAbsences data models.
    const end = convertDateToApiParamDateTime(
        new UpToAndIncludingDate(data.end).transformToUpToButExcludingDate().date,
    );

    const body = {
        type: 'leaveOfAbsences',
        attributes: {
            start: data.start,
            end,
            reason: data.reason || '',
            hours: data.duration,
            status: 'approved',
        },
        relationships: {
            user: {
                data: {
                    type: 'users',
                    id: userId,
                },
            },
        },
    };

    return axios.post(
        `${process.env.REACT_APP_API_URL}/leave-of-absences/`,
        { data: body },
    );
};

const postLeaveOfAbsencesToLoket = (startDate, endDate) => {
    const lastParam = endDate ? `&to=${endDate}` : '';

    return axios.put(
        `${getLoketApiUrl()}/leave/sync?from=${startDate}${lastParam}/`,
    );
};

export const generateAbsenceHoursBody = (
    absenceId,
    payrollPeriodId,
    hours,
    weekNumber,
    type = 'absenceHours',
    leaveType,
) => ({
    type,
    attributes: {
        ...(leaveType && { leaveType }),
        hours,
        ...(weekNumber && { weekNumber }),
    },
    relationships: {
        [type === 'absenceHours' ? 'absence' : 'leaveOfAbsence']: {
            data: {
                type: type === 'absenceHours' ? TYPE_ABSENCE : TYPE_LEAVE_OF_ABSENCE,
                id: absenceId,
            },
        },
        ...(payrollPeriodId && {
            payrollPeriod: {
                data: {
                    type: 'payrollPeriods',
                    id: payrollPeriodId,
                },
            },
        }),
    },
});

const postLeaveOfAbsenceHours = (
    absenceId,
    payrollPeriodId,
    hours,
    weekNumber,
    leaveType,
) => {
    const body = generateAbsenceHoursBody(
        absenceId,
        payrollPeriodId,
        hours,
        weekNumber,
        'leaveOfAbsenceHours',
        leaveType,
    );

    return axios.post(
        `${process.env.REACT_APP_API_URL}/leave-of-absence-hours/`,
        { data: body },
    );
};

const patchLeaveOfAbsenceHours = (
    loaHoursId,
    absenceId,
    payrollPeriodId,
    hours,
    weekNumber,
    leaveType,
) => {
    const body = generateAbsenceHoursBody(
        absenceId,
        payrollPeriodId,
        hours,
        weekNumber,
        'leaveOfAbsenceHours',
        leaveType,
    );
    body.id = loaHoursId;

    return axios.patch(
        `${process.env.REACT_APP_API_URL}/leave-of-absence-hours/${loaHoursId}`,
        { data: body },
    );
};

const clearLeaveOfAbsenceHours = (id) => {
    const body = {
        id,
        type: 'leaveOfAbsenceHours',
        attributes: {
            hours: 0,
        },
    };

    return axios.patch(
        `${process.env.REACT_APP_API_URL}/leave-of-absence-hours/${id}`,
        { data: body },
    );
};

const deleteAbsenceHours = (id) => axios.delete(`${process.env.REACT_APP_API_URL}/absence-hours/${id}`);
const deleteLeaveOfAbsenceHours = (id) => axios.delete(`${process.env.REACT_APP_API_URL}/leave-of-absence-hours/${id}`);

export {
    getLeaveOfAbsences,
    postLeaveOfAbsenceAccept,
    postLeaveOfAbsenceDecline,
    postExchangeLink,
    getAbsences,
    getAbsencesInDateRange,
    patchAbsence,
    deleteAbsence,
    postAbsence,
    postLeaveOfAbsence,
    patchLeaveOfAbsence,
    getAbsencesInDateRangeCSV,
    postLeaveOfAbsenceHours,
    patchLeaveOfAbsenceHours,
    deleteAbsenceHours,
    deleteLeaveOfAbsenceHours,
    postLeaveOfAbsencesToLoket,
    clearLeaveOfAbsenceHours,
};
