import trans from '../../helpers/trans';
import { AccessToken } from '../AccessToken';
import { FetchResult, FetchResultType } from '../FetchResult';

export const fetchRefreshToken = async (refreshToken: string): Promise<FetchResult<AccessToken, string>> => {
    try {
        const stringifiedRefreshToken = JSON.stringify(refreshToken);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/token/refresh`, {
            body: JSON.stringify({ refresh_token: JSON.parse(stringifiedRefreshToken) }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                TENANT: process.env.REACT_APP_TENANT || window.location.hostname,
            },
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[fetchRefreshToken]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const verifyPinApiCall = async (employeeNumber: number, pincode: string): Promise<FetchResult<AccessToken, string>> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/token/employee`, {
            body: JSON.stringify({ employee_number: employeeNumber, pincode }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                TENANT: process.env.REACT_APP_TENANT || window.location.hostname,
            },
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: 200,
            type: FetchResultType.Success,
            data: await response.json(),
        };
    } catch (error) {
        console.error('[verifyPinApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
