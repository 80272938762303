import { FC, ReactElement, useState } from 'react';

import { logout } from '../../../redux/app/appActions';
import { LoadingSpinner } from '../../components';
import { Pincode } from '../../containers';
import trans from '../../helpers/trans';
import { verifyPin } from '../../redux/authenticatedUser/authenticatedUserActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

const ConnectedPincode: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const { user } = useTypedSelector(state => state.authenticatedUserReducer);

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleLogoutButtonClick = (): void => {
        dispatch(logout());
    };

    const handleSubmitPin = async (pin: string): Promise<void> => {
        setErrorMessage('');
        if (user?.employeeNumber) {
            setIsLoading(true);

            const result = await dispatch(verifyPin(user.employeeNumber, pin));
            if (!result) {
                setErrorMessage(trans('containers.pincode.pincodeInvalid'));
            }

            setIsLoading(false);
        } else {
            dispatch(logout());
        }
    };

    return (
        <>
            {isLoading && <LoadingSpinner />}
            <Pincode
                errorMessage={errorMessage}
                onLogoutButtonClick={handleLogoutButtonClick}
                onSubmitPin={handleSubmitPin}
            />
        </>
    );
};

export default ConnectedPincode;
